// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-agb-js": () => import("./../../../src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-backbuch-1-js": () => import("./../../../src/pages/backbuch1.js" /* webpackChunkName: "component---src-pages-backbuch-1-js" */),
  "component---src-pages-backbuch-2-js": () => import("./../../../src/pages/backbuch2.js" /* webpackChunkName: "component---src-pages-backbuch-2-js" */),
  "component---src-pages-bestellung-js": () => import("./../../../src/pages/bestellung.js" /* webpackChunkName: "component---src-pages-bestellung-js" */),
  "component---src-pages-einweckbuch-js": () => import("./../../../src/pages/einweckbuch.js" /* webpackChunkName: "component---src-pages-einweckbuch-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-katharina-js": () => import("./../../../src/pages/katharina.js" /* webpackChunkName: "component---src-pages-katharina-js" */),
  "component---src-pages-kochbuch-js": () => import("./../../../src/pages/kochbuch.js" /* webpackChunkName: "component---src-pages-kochbuch-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-leseprobe-js": () => import("./../../../src/pages/leseprobe.js" /* webpackChunkName: "component---src-pages-leseprobe-js" */)
}

